import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ResetReport,
  SetReportType,
} from '../reports/states/report/report.actions';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public constructor(private store: Store) {}

  public async createReport(reportType: string): Promise<void> {
    if (reportType === 'general') {
      return await firstValueFrom(
        this.store.dispatch(new ResetReport()).pipe(
          switchMap(() => this.store.dispatch(new SetReportType(reportType))),
          switchMap((state) =>
            this.store.dispatch(
              new Navigate([`/reports/${state.report.reportType.slug}/1`])
            )
          )
        )
      );
    }

    return await firstValueFrom(this.store.dispatch(new Navigate(['/reports/soccer'])));
  }
}
