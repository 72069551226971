<app-tiles>
  <app-tile
    (click)="createReport('general')"
    backgroundColor="#f17201"
    [heightFactor]="1.2"
    i18n="Home|general report label@@homeGeneralReportLabel"
    >Melding algemeen</app-tile
  >
  <app-tile
    (click)="createReport('soccer')"
    backgroundColor="#00a200"
    i18n="Home|Soccer report label@@homeSoccerReportLabel"
    >Melding voetbal</app-tile
  >
  <app-tile
    backgroundColor="#01a1ff"
    routerLink="/about"
    i18n="Home|About label@@homeAboutLabel"
    >Over deze app</app-tile
  >
  <app-tile
    backgroundColor="#ea2e9e"
    routerLink="/privacy"
    [heightFactor]="0.8"
    i18n="Home|Privacy label@@homePrivacyLabel"
    >Privacy</app-tile
  >
</app-tiles>

<router-outlet></router-outlet>
